/* ============= General Setting  ================ */

$brand-blue:rgb(0,144,249);
$brand-violet-light: rgb(161,144,249);
$brand-violet-dark: rgb(72,56,229);
$brand-grey:rgb(237,237,237);


.brand-links{  
    color:$brand-blue ;
}
.brand-links:hover{   
    color:$brand-violet-light;
}
.brand-color{
    color:$brand-blue;
}

