@media (min-width: 992px) {
  [data-kt-app-header-fixed='true'] .app-header {
    position: absolute !important;
  }
  [data-kt-app-sidebar-fixed='true'] .app-sidebar {
    position: absolute !important;
  }
  [data-kt-app-sidebar-fixed='true'] .app-sidebar-menu {
    flex: none !important;
  }
  [data-kt-app-sidebar-fixed='true'] .app-sidebar-wrapper {
    height: 100% !important;
  }
  [data-kt-app-sidebar-fixed='true'] .app-sidebar-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  [data-kt-app-sidebar-fixed='true'] .app-page {
    position: relative !important;
    height: 100% !important;
  }
}
