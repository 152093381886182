.risk-header {
  padding: 10px;
  border: 1px thin grey;
  border-radius: 10px;
  background: linear-gradient(to bottom, rgba(128, 128, 128, 0.5), rgba(128, 128, 128, 0));
  /* Adjust colors and opacity as needed */
  display: flex;
}

.risk-details {
  margin-left: 1em;
  margin-right: 1em;
  margin: 10px;
  border: 1px solid var(--bs-gray-400);
  border-radius: 10px;
  display: flex;
  /* align-items: center; */
  flex-direction: column;
}

.risk-name {
  margin-left: auto;
  /* Move the name to the right, centering it horizontally */
  margin-right: auto;
  /* color:rgb(199, 195, 195) */
  color: var(--bs-text-dark) !important;
}

.activity-title {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom, rgba(128, 128, 128, 0.5), rgba(128, 128, 128, 0));
  width: 100%;
  border-radius: 10px;
  padding: 5px;
}

.risk-container {
  margin: 4px;
  margin-bottom: 8px;
}

.otp-group {
  display: flex;
  width: 100%;
  max-width: 360px;
  column-gap: 10px;
}

.otp-input {
  width: 100%;
  height: 60px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  line-height: 1;
}