/* ============= Index  ================ */
.bg-index {
  background-color: $body-bg-brand-dark;
}

.subtitle {
  color: #f0f0f0;
  font-weight: 500;
}

.navbar-brand {
  height: 42px;
}

/* ============= Carousel  ================ */

.swiper-button-prev,
.swiper-button-next {
  color: white !important;
  font-size: 24px;
  opacity: 0.7;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  opacity: 1;
}
.carousel-card {
  position: relative;
  transition: 0.3s ease;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.1);
  overflow: hidden;
  box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
}
.title-carousel {
  color: #fff;
}
.description-carousel {
  color: #fff;
  // font-size: 1.1em;
  font-size: 14px;
}
.descrip-footer {
  font-size: 14px;
}
.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.image-container {
  position: relative;
  // height: 30.5em;
  height: 380px;
}
.image-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.fotoMini {
  position: relative;
  // height: 8em;
  height: 110px;
  
}
.fotoMini img {
  width: 100%;
  height: 100%;
}
.image-hover {
  transition: filter 0.3s, cursor 0.3s;
}

.image-hover:hover {
  filter: brightness(0.8); /* Oscurecer un poco */
  cursor: pointer;
}
/* ============= Contact Button  ================ */
.btn-contact {
  border: 1px solid #fff !important;
  color: #fff !important;
  font-weight: 500 !important;
  box-shadow: inset 0 0 0 0 #fff !important;
}
.btn-contact:hover {
  color: black !important;
  border-color: #fff !important;
  background: rgb(255, 255, 255) 45% !important;
}

/* ============= Footer Index  ================ */
.Img-footer {
  height: 89px;
  object-fit: cover;
}
.card-footer {
  background: linear-gradient(270deg, #0083fe 0%, rgba(0, 131, 254, 0) 100%);
  border-radius: 15px !important;
}

/* ============= Login Modal ================ */

.modal-login {
  backdrop-filter: blur(1rem);
  background-color: rgba(0, 0, 0, 0.5);
}
/* =============  Modal user ================ */
.modal-user {
  backdrop-filter: blur(0.5rem);
  background-color: rgba(0, 0, 0, 0.5);
}

/* ============= Login Button ================ */

.btn-ingresar {
  border: none !important;
  color: white !important;
  font-weight: 500;
  width: 100%;
  box-shadow: inset 0 0 0 0 rgb(0, 144, 249) !important;
  transition: ease-out 0.5s;
  background: rgb(0, 144, 249);
  background: linear-gradient(83deg, rgba(0, 144, 249, 1) 0%, rgba(161, 144, 249, 1) 100%);
}

.btn-ingresar:hover {
  border: none;
  box-shadow: inset 500px 0 0 0 rgb(0, 144, 249) !important;
}
/* ============= Modal Link ================ */
.login-btn {
  color: #fff;
  background-color: transparent;
  border: none;
  cursor: pointer;
  // font-size: 1.4em;
  font-size: 18px;
  font-weight: 700;
}
.login-btn:hover {
  color: $brand-violet-light;
}

.link-doble-factor {
  color: rgba(0, 144, 249, 1);
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 0.9em;
  font-weight: 700;
}
.link-doble-factor:hover {
  color: $brand-violet-light;
  text-decoration: none;
}

.btn-ingresar-small {
  border: none !important;
  color: white !important;
  font-weight: 500;
  box-shadow: inset 0 0 0 0 rgb(0, 144, 249) !important;
  transition: ease-out 0.5s;
  background: rgb(0, 144, 249);
  background: linear-gradient(83deg, rgba(0, 144, 249, 1) 0%, rgba(161, 144, 249, 1) 100%);
}

.btn-ingresar-small:hover {
  border: none;
  box-shadow: inset 500px 0 0 0 rgb(0, 144, 249) !important;
}

@media screen and (max-width: 576px) {
  .title-index {
    font-size: 1.3em;
    text-align: center;
  }
  .subtitle {
    font-size: 0.9em;
  }
  .navbar-brand {
    height: 30px;
  }
  .login-btn {
    font-size: 1.2em;
  }
  .image-container {
    height: 120px;
  }
  .fotoMini {
    height: 50px;
  }
  .description-carousel {
    font-size: 0.9em;
  }
  .title-carousel {
    font-size: 1em;
  }
  .title-footer-i {
    font-size: 1.1em;
  }
  .descrip-footer {
    font-size: 0.8em;
  }
  .Img-footer {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .card-footer {
    border-radius: 5px !important;
  }
}
@media screen and (min-width: 415px) and (max-width: 531px) {
  .image-container {
    height: 160px;
  }
}
@media screen and (min-width: 531px) and (max-width: 576px) {
  .image-container {
    height: 200px;
  }
  .title-index {
    font-size: 1.4em;
  }
  .subtitle {
    font-size: 1em;
  }
  .fotoMini {
    height: 80px;
  }
  .title-footer-i {
    font-size: 1.4em;
  }
  .descrip-footer {
    font-size: 1.1em;
  }
  .description-carousel {
    font-size: 1em;
  }
  .title-carousel {
    font-size: 1.3em;
  }
}
@media screen and (min-width: 576px) and (max-width: 768px) {
  .image-container {
    height: 200px;
    
  }
  .title-index {
    // font-size: 1.9em;
    font-size: 16px;
  }
  .subtitle {
    // font-size: 1.2em;
    font-size: 13px;
  }
  .fotoMini {
    height: 80px;
  }
  .title-footer-i {
    // font-size: 1.4em;
    font-size: 14px;
  }
  .descrip-footer {
    // font-size: 1.1em;
    font-size: 12px;
  }
  .description-carousel {
    // font-size: 1em;
    font-size: 14px;
  }
  .title-carousel {
    // font-size: 1.3em;
    font-size: 12px;
  }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .image-container {
    height: 315px;
  }
  .title-index {
    font-size: 21px;
    // font-size: 1.9em;
  }
  .subtitle {
    font-size: 14px;
    // font-size: 1.2em;
  }
  .fotoMini {
    height: 80px;
  }
  .title-footer-i {
    // font-size: 1.1em;
    font-size: 18px;
  }
  .descrip-footer {
    // font-size: 0.9em;
    font-size: 10px;
  }
  .description-carousel {
    // font-size: 1em;
    font-size: 12px;
  }
  .title-carousel {
    // font-size: 1.3em;
    font-size: 18px;
  }
  .btn-contact {
    // font-size: 0.9em;
    font-size: 12px;

  }
  .bg-index {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .image-container {
    // height: 365px;
    height: 341px;
  }
  .title-index {
    // font-size: 1.9em;
    font-size: 24px;
  }
  .subtitle {
    // font-size: 1.2em;
    font-size: 14px;
  }
  .fotoMini {
    height: 90px;
    // height: 6em;
  }
  .title-footer-i {
    // font-size: 1.1em;
    font-size: 18px;
  }
  .descrip-footer {
    // font-size: 0.9em;
    font-size: 11.5px;

  }
  .description-carousel {
    // font-size: 1em;
    font-size: 12px;
  }
  // .title-carousel {
  //   font-size: 1.3em;
  // }
  .btn-contact {
    font-size: 14px;
    // font-size: 0.9em;
  }
  .bg-index {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (min-width: 1200px) {
  .btn-contact {
    font-size: 1.2em;
  }
  .bg-index {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.config .accordion-item {
  border: none; 
}

.config .accordion-button{
  border: none;
}

.title-accordion-config  {
  color: #78829d!important;
  font-size:13.9px!important;
}

.react-datepicker-wrapper{
  width:100% !important;
}

@media (min-width: 992px){
    .app-content {
        padding-top: 0px; 
        padding-bottom: 30px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .app-container {
      padding-left: 10px !important;
      padding-right: 10px !important;
  }    
}

.card-body-collapse {
  max-height: 1000px; 
  overflow: hidden;
  transition: max-height 0.3s ease; 
}
@media (min-width: 992px){
//   .container {
//     max-width: 1037px;
// }
}

@media (min-width: 1400px){

  .container-xxl  {
    max-width: 1525px;
}
// .container {
//   max-width: 1525px;
// }
}


.custom-datepicker {
  color: var(--input-solid-color)!important;
  border-color: hsl(0, 0%, 80%)!important;
}

.custom-datepicker .react-datepicker__input-container input:disabled {
  border-color: hsl(0, 0%, 80%)!important;
}

.table-footer-border {
  border-top: 2px solid var(--bs-gray-200) !important;
}

.table-footer-power {
  border-top: 1px dashed var(--bs-border-color)!important;

}



.View-BI {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.View-BI .iframe-BI {
  width: 100%;
  height: 100%;
}

.transladar-arriba {
  transform: translateY(-67px); 
}
.transladar-arriba-table {
  transform: translateY(-44px); 
}

.custom-dropdown-toggle:hover {
  background-color: #f1faff;
  color: #333;
}
.custom-dropdown-toggle-item:hover {
  background-color: #f1faff;
  color: #0095e7;
}